@if (label) {
  <label
    class="ui-label ui-label--{{ labelSize }}"
    [ngClass]="{ 'ui-label--required': isRequired }"
    [attr.for]="inputId"
  >
    {{ label }}
  </label>
}
<div #anchor class="ui-input__container ui-input__container--with-addons">
  <div class="ui-input__addon-wrapper">
    <ng-content select=".addon-prepend"></ng-content>
  </div>

  <div class="ui-input__editor" (click)="onOpenDropdown()">
    @if (searchable) {
      <input
        ngbDropdownAnchor
        [placeholder]="placeholder"
        [value]="searchText"
        (keyup)="onSearchInputKeyUp($event)"
        (focusin)="onSearchInputFocusIn($event)"
        (focusout)="onSearchInputFocusOut($event)"
        class="ui-input__input ui-input__input--searchable"
      />
    } @else if (selectedItemTemplate && selectedOption != null) {
      <ng-template
        [ngTemplateOutlet]="selectedItemTemplate"
        [ngTemplateOutletContext]="{ $implicit: selectedOption }"
      ></ng-template>
    } @else {
      <input
        ngbDropdownAnchor
        [placeholder]="placeholder"
        [readOnly]="true"
        [value]="selectedKeyLabel"
        class="ui-input__input"
      />
    }

    @if (shouldDisplayErrors) {
      <pxw-icon class="ui-input__error-icon" icon="info" size="sm" />
    }
    <pxw-icon class="ui-input__caret-icon" icon="caret-down" size="sm" />
  </div>
  <div class="ui-input__addon-wrapper" (click)="onClear()">
    @if (displayClear && value) {
      <pxw-icon class="ui-select-box__clear ui-clickable" icon="close" />
    }
  </div>
</div>

<div
  class="ui-select__dropdown"
  ngbDropdownMenu
  [ngStyle]="{ 'width.px': anchor.clientWidth }"
  [hidden]="hideDropdownMenu"
>
  @if (options.length > 0 || (searchable && allowNewValues)) {
    <ul
      class="ui-select__options"
      [ngStyle]="{ 'maxHeight.px': this.maxDropdownHeight }"
      cdkListbox
      [cdkListboxValue]="value ? [value] : []"
      (cdkListboxValueChange)="onSelectedOption($event)"
    >
      @if (
        addOptionTemplate &&
        searchable &&
        allowNewValues &&
        searchText &&
        !valueIsInOptions(searchText)
      ) {
        <li class="ui-select__item ui-select__item--add" [cdkOption]="searchText">
          <ng-template
            [ngTemplateOutlet]="addOptionTemplate"
            [ngTemplateOutletContext]="{ $implicit: searchText }"
          ></ng-template>
        </li>
      }
      @for (option of options | pxwSelectBoxOptionFilter: filterText : valueKey; track option) {
        <li class="ui-select__item" [cdkOption]="option | pxwSelectBoxOptionKey: valueKey">
          <ng-template
            [ngTemplateOutlet]="optionItemTemplate || defaultOptionItemTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-template>
        </li>
      }
    </ul>
  } @else {
    <p class="ui-select__empty-options">
      <ng-template
        [ngTemplateOutlet]="emptyOptionsTemplate || defaultEmptyOptionsTemplate"
      ></ng-template>
    </p>
  }
</div>

<ng-template #defaultOptionItemTemplate let-option>
  {{ option | pxwSelectBoxOptionKey: labelKey }}
</ng-template>

<ng-template #defaultEmptyOptionsTemplate>
  {{ emptyOptionsText }}
</ng-template>

@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
  </div>
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<ng-content></ng-content>
