import { Establishment, Feature, PlanType } from './types';

const basicFeatures: Feature[] = [
  'STATISTICS_EXPLORE_RESULTS',
  'PRODUCT_PRESENTATIONS',
  'PRODUCT_OPTIONS',
  'PRODUCT_GROUPS',
  'THEME',
  'QR_CODE',
  'DIGITAL_MENU',
  'COUPONS',
  'SLUG_CUSTOMIZATION',
  'CHECKOUT_CUSTOM_FIELDS',
  'WORKING_HOURS',
  'ALT_LANGUAGE',
  'ANNOUNCEMENTS',
];

const intermediateFeatures: Feature[] = [
  'SEO_SOCIAL_MEDIA',
  'FACEBOOK_PIXEL',
  'GOOGLE_ANALYTICS',
  'IMAGE_GALLERY',
  'STOCK_CONTROL',
  'SKU',
  'CATEGORY_AVAILABILITY',
  'THERMAL_PRINTING',
  'PRODUCT_TAGS',
  'DYNAMIC_CATEGORIES',
];

const advancedFeatures: Feature[] = [
  'DELIVERY_ZONES',
  'PRODUCTS_EXPORT',
  'IMAGE_GALLERY_EXTENDED',
  'GOOGLE_TAG_MANAGER',
  'PRODUCT_ITEM_IMAGES',
  'BULK_EDIT',
  'PRODUCTS_IMPORT',
  'INTEGRATIONS',
  // 'INTEGRATIONS_PUNIYA',
  // 'INTEGRATIONS_PEDIDOS_YA',
  // 'INTEGRATIONS_RAPIBOY',
  // 'INTEGRATIONS_JELP',
  // 'INTEGRATIONS_CABIFY',
  // 'INTEGRATIONS_CORREO_ARGENTINO',
  // 'INTEGRATIONS_EPICK',
  'ADVANCED_STATISTICS',
  'USER_MANAGEMENT',
];

export const getEstablishmentFeaturesByPlan = (
  plan: string,
  initialFeatures: Establishment['features'] = [],
): Feature[] => {
  const hasAdvancedFeatures = getHasAdvancedFeatures(initialFeatures);

  if (plan === PlanType.BASIC) {
    return initialFeatures.concat(basicFeatures);
  }

  if (plan === PlanType.INTERMEDIATE || (!plan && !hasAdvancedFeatures)) {
    return initialFeatures.concat(basicFeatures).concat(intermediateFeatures);
  }

  if (plan === PlanType.ADVANCED || (!plan && hasAdvancedFeatures)) {
    return initialFeatures
      .concat(basicFeatures)
      .concat(intermediateFeatures)
      .concat(advancedFeatures);
  }

  return initialFeatures;
};

export const getHasAdvancedFeatures = (features: Feature[] = []) => {
  return features.includes('DELIVERY_ZONES') || features.includes('PRODUCTS_EXPORT');
};
