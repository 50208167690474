import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'pxwPriceDiscount',
  standalone: true,
})
export class PriceDiscountPipe implements PipeTransform {
  transform(originalPrice?: number, discountPrice?: number): number {
    if (originalPrice && discountPrice && discountPrice <= originalPrice) {
      const percentage = (discountPrice * 100) / originalPrice;

      const discountPercentege = 100 - percentage;

      if (discountPercentege < 1) {
        return 1;
      }
      if (discountPercentege > 99) {
        return 99;
      }
      return Math.round(discountPercentege);
    }
    return 0;
  }
}
