/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  booleanAttribute,
  inject,
  signal,
} from '@angular/core';
import { DOCUMENT, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import clsx from 'clsx';
import {
  IconCaretDownComponent,
  IconCloseComponent,
  IconInfoComponent,
} from '@pedix-workspace/angular-ui-icons';
import {
  WithDefaultValidatorProps,
  composeValidatorFn,
  hasChangedAnyDefaultValidatorProps,
} from '../utils/defaultValidator';
import { CdkListbox, CdkOption, ListboxValueChangeEvent } from '@angular/cdk/listbox';
import { NgbDropdown, NgbDropdownAnchor, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { UiFormReactiveConfigService } from '../form-reactive-config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, fromEvent } from 'rxjs';
import { SelectBoxOptionFilterPipe } from './select-box-option-filter.pipe';
import { SelectBoxOptionKeyPipe } from './select-box-option-key.pipe';

let INPUT_UID = 0;

export type SelectBoxLabelSize = 'xs' | 'sm' | 'md';

export type SelectBoxValueType = string | undefined | null;

@UntilDestroy()
@Component({
  selector: 'pxw-select-box',
  standalone: true,
  templateUrl: './select-box.component.html',
  styleUrl: './select-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectBoxComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SelectBoxComponent,
    },
  ],
  imports: [
    NgClass,
    NgStyle,
    IconInfoComponent,
    IconCloseComponent,
    IconCaretDownComponent,
    CdkListbox,
    CdkOption,
    FormsModule,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownAnchor,
    NgTemplateOutlet,
    SelectBoxOptionFilterPipe,
    SelectBoxOptionKeyPipe,
  ],
  hostDirectives: [NgbDropdown],
})
export class SelectBoxComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator, WithDefaultValidatorProps
{
  @ViewChild(CdkListbox) listbox: CdkListbox;
  @ViewChildren(CdkOption) optionsRef: QueryList<CdkOption>;
  @ContentChild('selectedItemTemplate') selectedItemTemplate?: TemplateRef<{ $implicit: any }>;
  @ContentChild('optionItemTemplate') optionItemTemplate?: TemplateRef<{ $implicit: any }>;
  @ContentChild('emptyOptionsTemplate') emptyOptionsTemplate?: TemplateRef<void>;
  @ContentChild('addOptionTemplate') addOptionTemplate?: TemplateRef<{ $implicit: string }>;

  @Input({ required: true }) name: string;
  @Input() options: any[];
  @Input() labelKey: string;
  @Input() valueKey: string;
  @Input() label: string;
  @Input() labelSize: SelectBoxLabelSize = 'sm';
  @Input() helper: string;
  @Input() placeholder = '';
  @Input() messages: { [key: string]: string } = {};
  @Input() displayErrors = false;
  @Input() maxDropdownHeight = 250;
  @Input() displayClear = false;
  @Input() searchable = false;
  @Input() emptyOptionsText = 'No options to display';
  @Input() hideDropdownMenu = false;
  @Input() allowNewValues = true;
  @Input() searchTextTransformFn = (searchText: string) => searchText;

  @Input({ transform: booleanAttribute }) required?: boolean;
  @Input({ transform: booleanAttribute }) requiredTrue?: boolean;
  @Input({ transform: booleanAttribute }) email?: boolean;
  @Input() minLength?: number;
  @Input() maxLength?: number;
  @Input() pattern?: string | RegExp;

  @Output() searchTextChange = new EventEmitter<string>();
  @Output() addOption = new EventEmitter<string>();

  selectedKeyLabel = '';
  clearSearchOnUndefinedOptions = true;

  #value = signal<SelectBoxValueType>(null);
  #searchText = signal<string>('');
  #displayAllOptions = signal<boolean>(false);

  private injector = inject(Injector);
  private dropdown = inject(NgbDropdown);
  private ngZone = inject(NgZone);
  private document = inject(DOCUMENT);
  private rootEl: ElementRef<HTMLElement> = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);
  private selectBoxOptionKeyPipe = inject(SelectBoxOptionKeyPipe);

  protected inputId: string;
  protected isDisabled: boolean;
  protected clickOutsideListener: () => void;

  private onChange: (value: SelectBoxValueType) => void;
  private onTouched: () => void;
  private onValidatorChange: () => void;
  private validatorFn: ValidatorFn | null;
  private formReactiveConfigService = inject(UiFormReactiveConfigService);

  @HostBinding('class') get classNames() {
    return clsx('ui-input', `ui-select-box`, {
      'ui-input--disabled': this.isDisabled,
      'ui-input--with-helper': this.helper,
      'ui-input--with-errors': !this.isValid,
    });
  }

  get formControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      return ngControl.control as FormControl;
    }
    return null;
  }

  get isRequired() {
    return this.formControl?.hasValidator(Validators.required) || this.required === true;
  }

  get isValid() {
    return this.formControl?.valid;
  }

  get isTouched() {
    return this.formControl?.touched;
  }

  get errorEntries(): [string, any][] {
    return Object.entries(this.formControl?.errors || {});
  }

  get shouldDisplayErrors() {
    return (this.displayErrors || this.isTouched) && !this.isValid && !this.isDisabled;
  }

  get filterText() {
    if (this.searchable && !this.displayAllOptions) {
      return this.searchText;
    }
    return '';
  }

  get value(): SelectBoxValueType {
    return this.#value();
  }

  get searchText(): string {
    return this.#searchText();
  }

  get displayAllOptions(): boolean {
    return this.#displayAllOptions();
  }

  get selectedOption() {
    return this.options.find(option => {
      return this.value === this.selectBoxOptionKeyPipe.transform(option, this.valueKey);
    });
  }

  get selectedOptionLabel(): string {
    const selectedOption = this.selectedOption;

    if (!selectedOption) {
      return '';
    }
    return (this.selectedKeyLabel = this.selectBoxOptionKeyPipe.transform(
      selectedOption,
      this.labelKey,
    ));
  }

  ngOnInit(): void {
    this.inputId = `select-box-${++INPUT_UID}`;

    this.dropdown.autoClose = false;
    this.dropdown.openChange.pipe(untilDestroyed(this)).subscribe(isOpen => {
      if (isOpen) {
        this.#displayAllOptions.set(true);
      } else {
        this.onDropdownClose();
      }
    });
    this.ngZone.runOutsideAngular(() =>
      fromEvent(this.document, 'click')
        .pipe(
          filter(() => this.dropdown.isOpen()),
          untilDestroyed(this),
        )
        .subscribe(event => {
          if (!this.rootEl.nativeElement.contains(<HTMLElement>event.target)) {
            this.dropdown.close();

            this.cd.detectChanges();
          }
        }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasChangedAnyDefaultValidatorProps(changes)) {
      this.validatorFn = composeValidatorFn(this);

      if (this.formControl) {
        this.formControl.updateValueAndValidity();
      }
    }
  }

  writeValue(value: SelectBoxValueType) {
    this.#value.set(value ?? null);

    if (this.value !== null) {
      const selectedOptionLabel = this.selectedOptionLabel;

      if (selectedOptionLabel) {
        this.selectedKeyLabel = selectedOptionLabel;
      }
    } else {
      this.selectedKeyLabel = '';
    }
    if (this.searchable) {
      if (this.selectedKeyLabel) {
        this.updateSearchText(this.selectedKeyLabel);
      } else if (this.clearSearchOnUndefinedOptions) {
        this.updateSearchText('');
      }
    }
  }
  registerOnChange(onChange: (value: SelectBoxValueType) => void) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorFn?.(control) || null;
  }
  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }
  // ListboxValueChangeEvent<SelectBoxValueType>
  onSelectedOption($event: any) {
    const newValue = $event.value[0];

    if ($event.option?.element.classList.contains('ui-select__item--add')) {
      this.addOption.emit(newValue!);
    } else {
      this.setNewValue(newValue);
    }
    this.dropdown.close();
  }

  onOpenDropdown() {
    this.dropdown.open();

    if (!this.searchable) {
      setTimeout(() => {
        this.listbox.focus();
      });
    }
  }

  onClear() {
    this.updateSearchText('');
    this.setNewValue(null);
  }

  onSearchInputKeyUp($event: KeyboardEvent) {
    this.#displayAllOptions.set(false);

    if ($event.key === 'Escape') {
      this.dropdown.close();

      return;
    } else if ($event.key === 'Enter') {
      if (
        this.searchText !== this.value &&
        this.allowNewValues &&
        !this.valueIsInOptions(this.searchText)
      ) {
        this.addOption.emit(this.searchText);
      } else if (this.optionsRef.toArray()[0]?.getLabel() === this.searchText) {
        this.setNewValue(this.optionsRef.toArray()[0].value as SelectBoxValueType);
      }

      this.dropdown.close();

      return;
    }
    const newValue = (<HTMLInputElement>$event.target).value;

    if (!this.dropdown.isOpen()) {
      this.dropdown.open();
    }

    this.updateSearchText(newValue);
  }

  onSearchInputFocusIn($event: Event) {
    const input = $event.target as HTMLInputElement;

    setTimeout(() => {
      input.selectionStart = 0;
      input.selectionEnd = input.value.length;
    });
  }

  onSearchInputFocusOut($event: Event) {
    if (
      this.searchText !== this.value &&
      this.allowNewValues &&
      !this.valueIsInOptions(this.searchText)
    ) {
      this.addOption.emit(this.searchText);
    } else if (this.optionsRef.toArray()[0]?.getLabel() === this.searchText) {
      this.setNewValue(this.optionsRef.toArray()[0].value as SelectBoxValueType);
    }
  }

  onDropdownClose() {
    if (
      this.searchable &&
      this.searchText !== this.selectedKeyLabel &&
      this.clearSearchOnUndefinedOptions
    ) {
      this.updateSearchText(this.selectedKeyLabel);
    }
  }

  protected setNewValue(newValue: SelectBoxValueType) {
    this.writeValue(newValue);
    this.onChange(newValue);
    this.onValidatorChange();
    this.onTouched();
  }

  protected getErrorMessage([key, data]: [string, any]) {
    return this.messages?.[key] || this.formReactiveConfigService.getErrorMessage(key, data);
  }

  protected valueIsInOptions(value: string): boolean {
    return !!this.options.find(option => {
      return value === this.selectBoxOptionKeyPipe.transform(option, this.labelKey);
    });
  }

  private updateSearchText(searchText: string) {
    const transformedSearchText = this.searchTextTransformFn(searchText);

    this.#searchText.set(transformedSearchText);

    this.searchTextChange.emit(transformedSearchText);
  }
}
